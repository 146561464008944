<template>
  <v-autocomplete
    v-model="selected"
    :loading="loading"
    :search-input.sync="search"
    :label="label"
    :items="filteredItems"
    :return-object="true"
    item-value="id"
    item-text="full_name"
    clearable
  >
    <!-- Selected item -->
    <template class="" slot="selection" slot-scope="prop">
      <v-list style="max-width: 450px;" v-if="$vuetify.breakpoint.smAndUp">
        <v-list-item>
          <v-list-item-avatar>
            <v-avatar color="primary" :size="32">
              <img v-if="prop.item.image_url" :src="prop.item.image_url" />
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="prop.item.name"></v-list-item-title>
            <v-list-item-subtitle
              class="text--secondary"
              v-html="prop.item.business.display_name"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list-item-title
        v-html="prop.item.name"
        v-else
        style="max-width: 220px;"
      ></v-list-item-title>
    </template>

    <v-list slot="no-data">
      <v-list-item>
        <v-list-item-title>No resultat</v-list-item-title>
      </v-list-item>
    </v-list>

    <!--List item-->
    <template slot="item" slot-scope="prop">
      <v-list-item-avatar>
        <v-avatar color="primary" :size="32">
          <img v-if="prop.item.image_url" :src="prop.item.image_url" />
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="prop.item.name"></v-list-item-title>
        <v-list-item-subtitle
          v-html="prop.item.business.display_name"
        ></v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-icon>
        <img class="ml-2 rounded-sm" width="20" :src="'/img/flags/' + prop.item.country.flag" />
      </v-list-item-icon>
    </template>
  </v-autocomplete>
</template>
<script>
import _ from "lodash";
import { mapActions } from "vuex";

export default {
  props: {
    value: {
      required: true
    },
    label: {
      default: "Campaign"
    },
    items: {
      required: false,
      default: null
    },
    returnObject: {
      required: false,
      type: Boolean,
      default: false
    },
    filters: {
      required: false,
      type: Object,
      default: () => ({})
    },
    businessId: {
      default: null
    }
  },
  data: () => ({
    search: "",
    internalValue: null,
    campaigns: [],
    loading: false
  }),
  watch: {
    businessId(newVal) {
      if (newVal && this.filteredItems.indexOf(this.selected) === -1) {
        this.selected = null;
      }
    },
    search: {
      handler: _.debounce(function() {
        this.getItems();
      }, 500)
    },
    filters() {
      this.getItems();
    }
  },
  computed: {
    selected: {
      get() {
        return this.internalValue;
      },
      set(val) {
        if (typeof val === "undefined") {
          val = null;
        }

        this.internalValue = val;

        if (typeof val === "object" && val !== null) {
          if (this.returnObject) {
            this.$emit("input", val);
          } else {
            this.$emit("input", val.uuid);
          }
        } else {
          this.$emit("input", val);
        }
      }
    },
    filteredItems() {
      let itemsTmp =
        this.items && this.items.length > 0 ? this.items : this.campaigns;

      let items = this.cloneDeep(itemsTmp);

      // Check if selected exists
      if (this.internalValue !== null) {
        let exist = items.filter(item => item.uuid === this.internalValue.uuid);

        if (exist.length === 0) {
          items.push(this.internalValue);
        }
      }

      return items
        .filter(item => {
          return this.businessId
            ? item.business_uuid === this.businessId
            : true;
        })
        .map(item => {
          item.full_name = item.business.display_name + " " + item.name;
          return item;
        });
    }
  },
  methods: {
    ...mapActions("core/campaigns", ["loadCampaigns", "loadCampaign"]),
    getItems() {
      if (this.items === null) {
        this.loading = true;

        let filters = this.cloneDeep(this.filters);
        const params = {
          ...filters,
          search: this.search ? this.search : "",
          page: 1,
          limit: 25
        };

        this.loadCampaigns(params).then(campaigns => {
          this.campaigns = campaigns.response;
          this.loading = false;
        });
      }
    }
  },
  created() {
    this.getItems();
    // Get object if already has value
    if (this.value !== null && typeof this.value !== "undefined") {
      let selectedId = this.value;

      if (typeof this.value === "object") {
        selectedId = this.value.uuid;
      }

      this.loadCampaign({ uuid: selectedId }).then(campaign => {
        this.internalValue = campaign;
      });
    }
  }
};
</script>

<style></style>

<template>
  <div>
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Summarized report</div>
          <span class="subtitle-1 light-grey"
            >Overview of your performance. Shows today by default.</span
          >
        </div>

        <v-spacer></v-spacer>
        <v-tooltip :disabled="$vuetify.breakpoint.xsOnly" left>
          <template v-slot:activator="{ on }">
            <v-badge overlap color="badges">
              <template v-if="filtersCount > 0" v-slot:badge>{{
                filtersCount
              }}</template>
              <v-btn
                v-on="on"
                icon
                outlined
                color="primary"
                @click="
                  filtersTmp = cloneDeep(filters);
                  filterDialog = true;
                "
              >
                <v-icon small>fas fa-filter</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span>Filter</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text v-if="stats">
        <v-container fluid grid-list-lg>
          <v-layout wrap>
            <v-flex xs12 sm6 md6 xl4>
              <stat-widget
                icon="fal fa-hand-point-up"
                :title="$options.filters.local_numbers(stats.clicks)"
                subtitle="Clicks"
                flat
                bordered
              ></stat-widget>
            </v-flex>
            <v-flex xs12 sm6 md6 xl4>
              <stat-widget
                icon="fal fa-fingerprint"
                :title="$options.filters.local_numbers(stats.unique_clicks)"
                subtitle="Unique clicks"
                flat
                bordered
              ></stat-widget>
            </v-flex>
            <v-flex xs12 sm6 md6 xl4>
              <stat-widget
                icon="fal fa-shopping-basket"
                :title="$options.filters.local_numbers(stats.sales)"
                subtitle="Sales"
                flat
                bordered
              ></stat-widget>
            </v-flex>
            <v-flex xs12 sm6 md6 xl4>
              <stat-widget
                icon="fal fa-percentage"
                :title="$options.filters.local_numbers(stats.conversion) + '%'"
                subtitle="Conversion"
                flat
                bordered
              ></stat-widget>
            </v-flex>
            <v-flex xs12 sm6 md6 xl4>
              <stat-widget
                icon="fal fa-coins"
                :title="$options.filters.local_numbers(stats.value)"
                subtitle="Revenue"
                flat
                bordered
              ></stat-widget>
            </v-flex>
            <v-flex xs12 sm6 md6 xl4>
              <stat-widget
                icon="fal fa-money-bill-wave"
                :title="$options.filters.local_numbers(stats.commission)"
                subtitle="Commission"
                flat
                bordered
              ></stat-widget>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <div v-else>
        <v-container fluid grid-list-lg>
          <v-layout wrap>
            <v-flex xs12 sm6 md6 xl4 v-for="(item, k) in 6" :key="k">
              <v-skeleton-loader
                type="image"
                height="100px"
              ></v-skeleton-loader>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </v-card>
    <v-dialog v-model="filterDialog" max-width="550px">
      <v-card>
        <v-card-title class="headline" primary-title>
          Filter
        </v-card-title>
        <v-card-text>
          <business-autocomplete
            v-model="filtersTmp.business_uuid"
            :return-object="false"
          ></business-autocomplete>
          <campaign-autocomplete
            v-model="filtersTmp.campaign_uuid"
            :return-object="false"
          ></campaign-autocomplete>
          <date-picker
            v-model="filtersTmp.start_date"
            label="Start date"
            cancel-button
            :reset-button="false"
          ></date-picker>
          <date-picker
            v-model="filtersTmp.end_date"
            label="End date"
            cancel-button
            :reset-button="false"
          ></date-picker>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="
              filters = cloneDeep(filtersTmp);
              filterDialog = false;
            "
          >
            Filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DatePicker from "@/components/common/filters/DatePicker";
import StatWidget from "../../../widgets/StatWidget";
import local_numbers from "@/helpers/filters/local_numbers";
import search_filters_url from "@/helpers/mixins/search_filters_url";
import { mapActions } from "vuex";
import BusinessAutocomplete from "../../../autocompletes/BusinessAutocomplete";
import CampaignAutocomplete from "../../../autocompletes/CampaignAutocomplete";

export default {
  components: {
    CampaignAutocomplete,
    BusinessAutocomplete,
    StatWidget,
    DatePicker
  },
  filters: { local_numbers },
  mixins: [search_filters_url],
  data: () => ({
    stats: null,
    filterDialog: false,
    filtersTmp: {},
    loading: false,
    filters: {}
  }),
  computed: {
    filtersCount() {
      return Object.keys(this.filters).length;
    }
  },
  watch: {
    filters: {
      handler: function() {
        this.setUrlSearchFilters(this.filters);
        this.loadStats();
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("core", ["getStats"]),
    loadStats() {
      this.loading = true;

      let params = {
        start_date: this.filters.start_date,
        end_date: this.filters.end_date
      };

      if(this.filters.business_uuid) {
        params.business_uuid = this.filters.business_uuid;
      }

      if(this.filters.campaign_uuid) {
        params.campaign_uuid = this.filters.campaign_uuid;
      }

      this.getStats(params).then(
        response => {
          this.stats = response[0];
        },
        () => {
          this.setSnackError("Could not fetch data.");
        }
      );
    }
  },
  created() {
    this.filters = this.getUrlSearchFilters();
    if (Object.keys(this.filters).length === 0) {
      this.filters.start_date = this.$moment().format("YYYY-MM-DD");
      this.filters.end_date = this.$moment().format("YYYY-MM-DD");
    }
    this.loadStats();
  }
};
</script>
